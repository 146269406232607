<template>
	<div class="project-info">
		<!-- <div class="breadcrumb">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item to="/successful"> 中标大数据 </el-breadcrumb-item>
          <el-breadcrumb-item>项目信息查询</el-breadcrumb-item>
        </el-breadcrumb>
      </div> -->
		<div class="title-card">
			<div class="title-box">
				<div class="title">中标项目信息</div>
				<!-- <div class="title-right">
            <span v-show="queryLimit == -1">不限查询次数</span>
            <span v-show="queryLimit != -1 && queryLimit != 0"
              >当天消耗：{{ allQueryLimit - queryLimit }}次 / 剩余次数：{{
                queryLimit
              }}次</span
            >
          </div> -->
			</div>
			<div class="search-box">
				<div class="search-form">
					<div class="form-item">
						<div class="form-top">
							<div class="form-lable">项目名称</div>
							<div>
								<el-autocomplete v-model.trim="conPO.projectName" :fetch-suggestions="getProjectName" :trigger-on-focus="false" placeholder="请输入项目名称" clearable>
									<template slot-scope="{item}">
										<el-tooltip effect="light" :content="item.value" placement="right-end">
											<div>
												{{ item.value }}
											</div>
										</el-tooltip>
									</template>
								</el-autocomplete>
							</div>
							<div class="form-lable lable">招标人</div>
							<div>
								<el-autocomplete v-model.trim="conPO.tenderee" :fetch-suggestions="getTenderee" :trigger-on-focus="false" placeholder="请输入招标人" clearable>
									<template slot-scope="{item}">
										<el-tooltip effect="light" :content="item.value" placement="right-end">
											<div>
												{{ item.value }}
											</div>
										</el-tooltip>
									</template>
								</el-autocomplete>
							</div>
							<div class="form-lable lable">中标人</div>
							<div>
								<el-autocomplete v-model.trim="conPO.bidWinner" :fetch-suggestions="getBidWinner" :trigger-on-focus="false" placeholder="请输入中标人" clearable>
									<template slot-scope="{item}">
										<el-tooltip effect="light" :content="item.value" placement="right-end">
											<div>
												{{ item.value }}
											</div>
										</el-tooltip>
									</template>
								</el-autocomplete>
							</div>
							<div class="form-lable lable">项目类型</div>
							<div>
								<!-- <el-select v-model="conPO.projectType" multiple collapse-tags placeholder="请选项目类型" clearable>
                  <el-option v-for="(item, index) in typeList" :key="index" :label="item" :value="item">
                  </el-option>
                </el-select> -->
								<select-type ref="selectType"></select-type>
							</div>
						</div>
						<div class="form-top">
							<div class="form-lable">
								<span style="color: red">*</span>
								所在地区
							</div>
							<div>
								<select-area ref="selectArea" :key="selectAreaKey"></select-area>
								<!-- <el-cascader
                    :options="areaList"
                    :props="{
                      value: 'code',
                      label: 'name',
                      children: 'childrenList',
                      multiple: true,
                      checkStrictly: true,
                    }"
                    clearable
                    v-model="conPO.areaId"
                    collapse-tags
                    :show-all-levels="false"
                    ref="area"
                  ></el-cascader> -->
							</div>
							<div class="form-lable lable">项目金额</div>
							<el-form ref="rulesPrice" :model="conPO" :rules="rules" style="display: flex; align-items: center">
								<div>
									<el-form-item prop="winnerPriceStart" style="margin-bottom: 0">
										<el-input v-model.trim="conPO.winnerPriceStart" oninput="value=value.replace(/[^0-9.]/g,'')" placeholder="最低金额（万）" @blur="blurPrice('start')" clearable></el-input>
									</el-form-item>
								</div>
								<div class="line" style="margin-top: 1px"></div>
								<div>
									<el-form-item prop="winnerPriceEnd" style="margin-bottom: 0">
										<el-input v-model.trim="conPO.winnerPriceEnd" oninput="value=value.replace(/[^0-9.]/g,'')" placeholder="最高金额（万）" @blur="blurPrice('end')" clearable></el-input>
									</el-form-item>
								</div>
							</el-form>
							<div class="note">
								<span class="iconfont icon-zhushibiaozhui"></span>
								<div class="note-title animated fadeIn">某金额以上可只填最低金额；某金额以下可只填最高金额</div>
							</div>
						</div>
						<div class="form-bottom">
							<div class="form-lable">
								<span style="color: red">*</span>
								中标时间
							</div>
							<div class="date-picker">
								<el-date-picker v-model="conPO.winnerDateStart" type="month" :picker-options="startTime" placeholder="选择开始日期" value-format="yyyy-MM"></el-date-picker>
								<!-- <div class="iconfont-box">
                    <span class="iconfont icon-riliriqi2"></span>
                  </div> -->
							</div>
							<div class="line"></div>
							<div class="date-picker">
								<el-date-picker v-model="conPO.winnerDateEnd" type="month" :picker-options="endTime" placeholder="选择结束日期" value-format="yyyy-MM"></el-date-picker>
								<!-- <div class="iconfont-box">
                    <span class="iconfont icon-riliriqi2"></span>
                  </div> -->
							</div>
							<div class="search-button">
								<el-button type="primary" @click="handleQuery">搜索</el-button>
								<el-button @click="resetQuery">清空</el-button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="list-box">
			<div class="empty-box" v-show="total == 0">
				<empty :name="'暂无数据'"></empty>
			</div>
			<div class="result" v-show="total != 0">
				<div style="display: flex; align-items: center">
					<!-- <div class="result-num" v-show="memberId != 7 && memberId != 8 && memberId != ''"> -->
					<div class="result-num">
						<span>共找到</span>
						<span class="num">{{ total }}</span>
						<span>条结果</span>
					</div>
					<div v-show="false" style="margin-left: 20px">时间：2021年1月-2022年12月</div>
				</div>
				<div class="download-box" @click="toDownload" v-if="downloadButtonShow">
					<span class="iconfont icon-download"></span>
					<span>下载</span>
				</div>
			</div>
			<div class="result-box" v-show="total != 0">
				<el-table :data="tableData" style="width: 100%" v-table-move="['table']" @sort-change="sortChange">
					<el-table-column prop="index" label="序号" width="74" align="center" show-overflow-tooltip></el-table-column>
					<el-table-column prop="projectName" label="项目名称" align="center" width="300" show-overflow-tooltip>
						<template slot-scope="scope">
							<div class="text-ellipsis">
								{{ scope.row.projectName }}
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="province" label="省份" width="110" align="center" show-overflow-tooltip></el-table-column>
					<el-table-column prop="city" label="地市" width="110" align="center" show-overflow-tooltip></el-table-column>
					<el-table-column prop="projectCounty" label="区/县" width="110" align="center" show-overflow-tooltip></el-table-column>
					<el-table-column prop="projectType" label="项目类型" width="110" align="center" show-overflow-tooltip></el-table-column>
					<!-- :width="flexColumnWidth('tenderee', tableData)" -->
					<!-- :width="flexColumnWidth('bidWinner', tableData)" -->
					<!-- :width="flexColumnWidth('bidConsortium', tableData)" -->
					<el-table-column prop="tenderee" label="招标人" width="200" align="center" show-overflow-tooltip>
						<template slot-scope="scope">
							<div class="text-ellipsis">
								{{ scope.row.tenderee }}
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="bidWinner" label="中标人（牵头人）" width="200" align="center" show-overflow-tooltip>
						<template slot-scope="scope">
							<div class="text-ellipsis">
								{{ scope.row.bidWinner }}
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="bidConsortium" label="中标人（联合体）" width="200" align="center" show-overflow-tooltip>
						<template slot-scope="scope">
							<div class="text-ellipsis" v-for="(item, index) in scope.row.bidConsortium" :key="index">
								<el-tooltip :content="item" placement="top">
									<span>{{ item }}</span>
								</el-tooltip>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="winnerPrice" label="中标价（万元）" width="200" sortable="custom" :sort-orders="['ascending', 'descending']" align="center" show-overflow-tooltip :render-header="renderHeader"></el-table-column>
					<el-table-column prop="publishedDate" label="中标时间" width="110" sortable="custom" :sort-orders="['ascending', 'descending']" align="center" show-overflow-tooltip :render-header="renderHeader"></el-table-column>
					<el-table-column prop="projectLink" label="中标公告原文链接" width="180" align="center" show-overflow-tooltip v-if="linkShow">
						<template slot-scope="scope">
							<span class="project-link" @click="toLink(scope.row.projectLink)">{{ scope.row.projectLink }}</span>
						</template>
					</el-table-column>
				</el-table>
			</div>

			<!-- <div
        class="pagination-box"
        v-show="memberId != 7 && memberId != 8 && memberId != ''"
      > -->
			<div class="pagination-box">
				<pagination ref="pagination" :total="total" @change="paginChange" v-show="total != 0"></pagination>
			</div>
			<!-- <div
        class="go-member"
        v-show="memberId == 7 || memberId == ''"
        @click="toMember"
      >
        <img src="../../../assets/img/gomember.png" alt="" />
      </div> -->
		</div>
		<login-modal ref="loginModal"></login-modal>
		<member-modal ref="memberModal"></member-modal>
		<WebSocket></WebSocket>
	</div>
</template>

<script>
import Pagination from "@/components/Pagination";
import LoginModal from "@/components/loginModal";
import MemberModal from "@/components/MemberModal";
import Empty from "@/components/Empty";
import SelectArea from "@/components/SelectArea";
import SelectType from "@/components/SelectType";
import WebSocket from "../components/webSocket.vue";
export default {
	components: {
		Pagination,
		LoginModal,
		MemberModal,
		Empty,
		SelectArea,
		SelectType,
		WebSocket,
	},
	data() {
		let price = (rule, value, callback) => {
			if (!value) {
				callback();
			} else {
				const reg = /^[0-9]{1,18}([.][0-9]{1,6})?$/;
				if (reg.test(value)) {
					callback();
				} else {
					return callback(new Error("最多18位整数，6位小数"));
				}
			}
		};
		return {
			minDate: "",
			maxDate: "",
			linkShow: false,
			total: 0, // 结果数量
			isDefault: true,
			conPO: {
				projectName: "",
				tenderee: "",
				bidWinner: "",
				projectType: "",
				winnerPriceStart: "",
				winnerPriceEnd: "",
				winnerDateStart: "",
				winnerDateEnd: "",
				pageSize: 20, //页数
				pageNum: 1, // 页码
			}, // 查询条件
			rules: {
				winnerPriceStart: [
					{
						validator: price,
						trigger: "blur",
					},
				],
				winnerPriceEnd: [
					{
						validator: price,
						trigger: "blur",
					},
				],
			},
			typeList: [], // 分类数据
			tableData: [],
			allQueryLimit: "", // 总查询次数
			queryLimit: "", // 剩余查询次数
			selectAreaKey: "",
			sortData: {},
			isTime: true,
			memberId: "",
			downloadButtonShow:null
		};
	},
	created() {
		this.$api.successful
			.getPublishedDateRange()
			.then(res => {
				this.minDate = new Date(res.data.min);
				this.maxDate = new Date(res.data.max);
			})
			.catch(msg => {
				if (msg?.msg) {
					this.$message.error(msg?.msg);
				}
			});
	},
	computed: {
		startTime() {
			return {
				disabledDate: time => {
					if (this.conPO.winnerDateEnd) {
						return time.getTime() > new Date(this.conPO.winnerDateEnd).getTime() || time > this.maxDate || time < this.minDate;
					} else {
						return time > this.maxDate || time < this.minDate;
					}
				},
			};
		},
		endTime() {
			return {
				disabledDate: time => {
					if (this.conPO.winnerDateStart) {
						return time.getTime() < new Date(this.conPO.winnerDateStart).getTime() || time > this.maxDate || time < this.minDate;
					} else {
						return time > this.maxDate || time < this.minDate;
					}
				},
			};
		},
	},
	mounted() {
		this.memberId = this.$session.getUsers().memberId;
		this.$refs.pagination.pageSize = 20;
		// 数据埋点
		this.$api.home.buriedPoint({
			terminal: "60441001",
			consumerId: this.$session.getUsers() ? this.$session.getUsers().consumerId : "",
			positionCode: "604410011005",
		});
		this.linkShow = true;
		this.getAreaList();
		this.getBidProjectType();
		this.getProjectInfoDefalut();
		this.getDownloadButtonShow();
	},
	methods: {
		// 获取下载按钮是否显示
		getDownloadButtonShow() {
			let params = {
				childModuleCode:10021001 ,
				memberId: this.memberId,
			};
			this.$api.personalCenter.getDownloadButtonShow(params).then(res => {
				console.log("res", res);
				this.downloadButtonShow = res.data.downloadButtonShow=="10011001"?true:false;
			});
		},
		renderHeader(h, {column, $index}) {
			if (column.order === "ascending") {
				return (
					<div>
						<span>{column.label}</span>
						<i style='margin-left: 4px;color: #1181FA;' class='iconfont icon-shengxu'></i>
					</div>
				);
			} else if (column.order === "descending") {
				return (
					<div>
						<span>{column.label}</span>
						<i style='margin-left: 4px;color: #1181FA;' class='iconfont icon-jiangxu'></i>
					</div>
				);
			} else {
				return (
					<div>
						<span>{column.label}</span>
						<i style='margin-left: 4px;color: #333;' class='iconfont icon-jiangxu'></i>
					</div>
				);
			}
		},
		toMember() {
			let url = this.$router.resolve({
				path: `/member`,
			});
			window.open(url.href, "_blank");
		},
		// 项目信息查询-默认数据
		getProjectInfoDefalut() {
			const loading = this.$loading({
				lock: true,
				text: "搜索中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			this.$api.successful
				.getProjectInfoDefalut(this.conPO)
				.then(res => {
					let data = res.rows;
					data.forEach((item, index) => {
						item.index = (this.conPO.pageNum - 1) * this.conPO.pageSize + index + 1;
						item.publishedDate = item.publishedDate.slice(0, 10);
						// if(item.tenderee.indexOf(',') == -1) {
						//   return
						// } else {
						//   item.tenderee.split(',')
						//   this.$forceUpdate()
						// }
						if (item.bidConsortium) {
							if (item.bidConsortium.indexOf(",") == -1) {
								let arr1 = [];
								arr1.push(item.bidConsortium);
								item.bidConsortium = arr1;
								this.$forceUpdate();
							} else {
								let arr2 = item.bidConsortium.split(",");
								item.bidConsortium = arr2;
								this.$forceUpdate();
							}
							this.$forceUpdate();
						} else {
							item.bidConsortium = ["-"];
						}
						this.$forceUpdate();
					});
					this.$forceUpdate();
					this.tableData = data;
					this.total = res.total;
					if (res.total != 0) {
						this.isTime = true;
					} else {
						this.isTime = false;
					}
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				})
				.finally(() => {
					loading.close();
				});
		},
		// 输入最低/最高金额后
		blurPrice(type) {
			let {winnerPriceStart, winnerPriceEnd} = this.conPO;
			if (type == "start") {
				if (+winnerPriceStart > +winnerPriceEnd && winnerPriceEnd != "") {
					this.$message.error("最低金额不能大于最高金额");
					this.conPO.winnerPriceStart = "";
				}
			} else {
				if (+winnerPriceEnd < +winnerPriceStart && winnerPriceStart != "") {
					this.$message.error("最高金额不能小于最低金额");
					this.conPO.winnerPriceEnd = "";
				}
			}
		},
		// 项目名称联想搜索
		getProjectName(queryString, callback) {
			this.$api.successful
				.getProjectName({
					projectName: queryString,
				})
				.then(res => {
					let newData = [];
					res.data.forEach(item => {
						let obj = {
							value: item,
						};
						newData.push(obj);
					});
					callback(newData);
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 招标人联想搜索
		getTenderee(queryString, callback) {
			this.$api.successful
				.getTenderee({
					tenderee: queryString,
				})
				.then(res => {
					let newData = [];
					res.data.forEach(item => {
						let obj = {
							value: item,
						};
						newData.push(obj);
					});
					callback(newData);
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 中标人联想搜索
		getBidWinner(queryString, callback) {
			this.$api.successful
				.getBidWinner({
					bidWinner: queryString,
				})
				.then(res => {
					let newData = [];
					res.data.forEach(item => {
						let obj = {
							value: item,
						};
						newData.push(obj);
					});
					callback(newData);
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 省市区获取
		getAreaList() {
			this.$api.successful
				.getAreaList()
				.then(res => {
					for (let i = 0; i < res.data.length; i++) {
						res.data[i].type = "省";
						for (let j = 0; j < res.data[i].childrenList.length; j++) {
							res.data[i].childrenList[j].type = "市";
							for (let k = 0; k < res.data[i].childrenList[j].childrenList.length; k++) {
								res.data[i].childrenList[j].childrenList[k].type = "区";
							}
						}
					}
					this.$refs.selectArea.areaList = res.data;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 项目类型获取
		getBidProjectType() {
			this.$api.successful
				.getBidProjectType()
				.then(res => {
					console.log(res);
					// this.typeList = res.data;
					this.$refs.selectType.typeList = res.data;
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				});
		},
		// 自适应表格列宽
		flexColumnWidth(str, tableData, flag = "max") {
			// str为该列的字段名(传字符串);tableData为该表格的数据源(传变量);
			// flag为可选值，可不传该参数,传参时可选'max'或'equal',默认为'max'
			// flag为'max'则设置列宽适配该列中最长的内容,flag为'equal'则设置列宽适配该列中第一行内容的长度。
			str = str + "";
			let columnContent = "";
			if (!tableData || !tableData.length || tableData.length === 0 || tableData === undefined) {
				return;
			}
			if (!str || !str.length || str.length === 0 || str === undefined) {
				return;
			}
			if (flag === "equal") {
				// 获取该列中第一个不为空的数据(内容)
				for (let i = 0; i < tableData.length; i++) {
					if (tableData[i][str].length > 0) {
						// console.log('该列数据[0]:', tableData[0][str])
						columnContent = tableData[i][str];
						break;
					}
				}
			} else {
				// 获取该列中最长的数据(内容)
				let index = 0;
				for (let i = 0; i < tableData.length; i++) {
					if (tableData[i][str] === null) {
						return;
					}
					const now_temp = tableData[i][str] + "";
					const max_temp = tableData[index][str] + "";
					if (now_temp.length > max_temp.length) {
						index = i;
					}
				}
				columnContent = tableData[index][str] + "";
				let columnContentArr = columnContent.split(",");
				// 如果字符串转成数组大于1，去寻找数组中最长的数据

				if (columnContentArr.length > 1) {
					let newIndex = 0;
					for (let j = 0; j < columnContentArr.length; j++) {
						if (columnContentArr[j] === null) {
							return;
						}
						const now_temp = columnContentArr[j] + "";
						const max_temp = columnContentArr[newIndex] + "";
						if (now_temp.length > max_temp.length) {
							newIndex = j;
						}
					}
					columnContent = columnContentArr[newIndex];
				}
			}
			// console.log('该列数据[i]:', columnContent)
			// 以下分配的单位长度可根据实际需求进行调整
			let flexWidth = 0;
			for (const char of columnContent) {
				if ((char >= "A" && char <= "Z") || (char >= "a" && char <= "z")) {
					// 如果是英文字符，为字符分配10个单位宽度
					flexWidth += 10;
				} else if (char >= "\u4e00" && char <= "\u9fa5") {
					// 如果是中文字符，为字符分配18个单位宽度
					flexWidth += 18;
				} else {
					// 其他种类字符，为字符分配10个单位宽度
					flexWidth += 10;
				}
			}
			if (flexWidth < 60) {
				// 设置最小宽度
				flexWidth = 60;
			}
			return flexWidth;
		},
		// 点击筛选
		handleQuery() {
			// 判断用户是否登录
			if (this.$session.getUsers()) {
				this.$refs.pagination.page = 1;
				this.conPO.pageNum = 1;
				this.conPO.pageSize = 20;
				this.getProjectInfo();
			} else {
				this.$refs.loginModal.visible = true;
			}
		},
		// 获取表格数据
		getProjectInfo() {
			this.$refs.rulesPrice.validate(valid => {
				if (valid) {
					let {conPO} = this;
					let selectList = this.$refs.selectArea.selectList;
					// console.log(selectList);
					// let codeArr = [];
					// selectList.forEach(item => {
					//   codeArr.push(item.code);
					// });
					if (selectList.length == 0) {
						this.$message.error("请选择地区");
						return;
					}
					let province = [];
					let city = [];
					let projectCounty = [];
					selectList.forEach(item => {
						if (item.type == "省") {
							province.push(item.code);
						} else if (item.type == "市") {
							city.push(item.code);
						} else {
							projectCounty.push(item.code);
						}
					});
					conPO.province = province;
					conPO.city = city;
					conPO.projectCounty = projectCounty;
					// conPO.area = codeArr.toString();
					// if (codeArr.length == 1) {
					//   conPO.area = codeArr.toString() + ",";
					// }
					let params = JSON.parse(JSON.stringify(conPO));
					// params.projectType = params.projectType.toString();
					let projectType = this.$refs.selectType.typeSelect;
					// if (projectType.length == 0) {
					//   this.$message.error("请选择项目类型");
					//   return;
					// }
					params.projectType = projectType.toString();
					if (!conPO.winnerDateStart || !conPO.winnerDateEnd) {
						this.$message.error("请选择中标时间");
						return;
					}
					const loading = this.$loading({
						lock: true,
						text: "搜索中...",
						spinner: "el-icon-loading",
						background: "rgba(255, 255, 255, 0.3)",
						fullscreen: false,
					});
					this.$api.successful
						.getProjectInfo(params)
						.then(res => {
							let data = res.rows;
							data.forEach((item, index) => {
								item.index = (conPO.pageNum - 1) * conPO.pageSize + index + 1;
								item.publishedDate = item.publishedDate.slice(0, 10);
								// if(item.tenderee.indexOf(',') == -1) {
								//   return
								// } else {
								//   item.tenderee.split(',')
								//   this.$forceUpdate()
								// }
								if (item.bidConsortium) {
									if (item.bidConsortium.indexOf(",") == -1) {
										let arr1 = [];
										arr1.push(item.bidConsortium);
										item.bidConsortium = arr1;
										this.$forceUpdate();
									} else {
										let arr2 = item.bidConsortium.split(",");
										item.bidConsortium = arr2;
										this.$forceUpdate();
									}
									this.$forceUpdate();
								} else {
									item.bidConsortium = ["-"];
								}
								this.$forceUpdate();
							});
							this.$forceUpdate();
							this.tableData = data;
							this.total = res.total;
							this.isDefault = false;
							this.isTime = true;
							if (res.total != 0) {
								if (params.winnerDateStart || params.winnerDateEnd) {
									this.isTime = false;
								}
							} else {
								this.isTime = false;
							}
						})
						.catch(msg => {
							if (msg?.msg) {
								this.$message.error(msg?.msg);
							}
						})
						.finally(() => {
							loading.close();
						});
				} else {
					return false;
				}
			});
		},
		// 点击清空
		resetQuery() {
			this.conPO = {
				projectName: "",
				tenderee: "",
				bidWinner: "",
				projectType: "",
				winnerPriceStart: "",
				winnerPriceEnd: "",
				winnerDateStart: "",
				winnerDateEnd: "",
				pageSize: 20,
				pageNum: 1,
			};
			this.$refs.selectType.typeSelect = [];
			this.selectAreaKey = new Date().getTime();
			this.getAreaList();
		},
		// 分页方法
		paginChange(page, pageSize) {
			let {memberId} = this;
			if (memberId == 7 || memberId == "") {
				this.$refs.memberModal.open = true;
				return;
			}
			this.conPO.pageNum = page;
			this.conPO.pageSize = pageSize;
			if (this.isDefault) {
				this.getProjectInfoDefalut();
			} else {
				this.getProjectInfo();
			}
		},
		// 点击下载
		toDownload() {
			let {conPO} = this;
			let params = JSON.parse(JSON.stringify(conPO));
			delete params.pageNum;
			delete params.pageSize;
			let projectType = this.$refs.selectType.typeSelect.toString();
			params.projectType = projectType;
			const loading = this.$loading({
				lock: true,
				text: "导出中...",
				spinner: "el-icon-loading",
				background: "rgba(255, 255, 255, 0.3)",
				fullscreen: false,
			});
			this.$api.successful
				.exportProjectInfo(params)
				.then(res => {
					this.$utils.downloadFile(res, "项目信息.xlsx");
				})
				.catch(msg => {
					if (msg?.msg) {
						this.$message.error(msg?.msg);
					}
				})
				.finally(() => {
					loading.close();
				});
		},
		toLink(url) {
			this.$confirm("您即将跳转进入第三方网站，慧决策平台不对该网站内容及真实性负责，请注意您的账号和财产安全", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning",
			}).then(() => {
				window.open(url, "_blank");
			});
		},
		// 排序
		sortChange({prop, order}) {
			let {conPO} = this;
			console.log(prop, order);
			if (prop == "winnerPrice") {
				conPO.statisticSort = 1;
			} else if (prop == "publishedDate") {
				conPO.statisticSort = 2;
			}
			if (order == "ascending") {
				conPO.sortType = 1;
			} else if (order == "descending") {
				conPO.sortType = 0;
			} else {
				conPO.statisticSort = null;
				conPO.sortType = null;
			}
			console.log(conPO, "conPO");
			this.getProjectInfo();
		},
	},
};
</script>

<style lang="less">
@import "./index.less";
</style>
